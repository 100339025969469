@font-face {
  font-family: MicrosoftYaHei;
  src: url("http://oss.asfcyy.com/richTextImage/792348e053ea11eb9dc700163e3521a2.ttf");
}
@font-face {
  font-family: MicrosoftYaHeiBold;
  src: url("http://oss.asfcyy.com/richTextImage/8f0820e053ea11eb9dc700163e3521a2.ttf");
}
@font-face {
  font-family: SimSun;
  src: url("http://oss.asfcyy.com/richTextImage/a2f4df5853ea11eb9dc700163e3521a2.ttf");
}
@font-face {
  font-family: AdobeHeitiStd;
  src: url("http://oss.asfcyy.com/richTextImage/be6dbbc453ea11eb9dc700163e3521a2.otf");
}
@font-face {
  font-family: PingFangRegular;
  src: url("http://oss.asfcyy.com/richTextImage/db6eb67e53ea11eb9dc700163e3521a2.ttf");
}
@font-face {
  font-family: PingFangBold;
  src: url("http://oss.asfcyy.com/richTextImage/28f0635c53eb11eb9dc700163e3521a2.ttf");
}
@font-face {
  font-family: DFPSongW9;
  src: url("http://oss.asfcyy.com/richTextImage/4b36d05e53eb11eb9dc700163e3521a2.ttf");
}
@font-face {
  font-family: FZRUIZHJW;
  src: url("http://oss.asfcyy.com/richTextImage/b9aeb83a691c11eb9dc700163e3521a2.TTF");
}
.Clearfix:after{
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
body,ul{
  font-family: Arial, serif;
  margin:0;
  padding:0;
}
div{
  outline:none;
  word-break: break-all;
}
ul,li{
  display:block;
  list-style: none;
}
p{
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 32px;
  font-family: PingFangRegular,sans-serif;
  font-weight: bold;
}
hr{
  margin: 18px 0;
}
h3{
  height: 26px;
  line-height: 26px;
  font-size: 20px;
  margin: 26px 0;
}
video{
  display: block;
  margin: 20px auto;
}
.App {
  width: 100%;
  height: 100%;
}